import styles from '../css/main.scss';

import 'owl.carousel';

import isTouchDevice from './utils/is-touch-device.js';
import './plugins/jquery.unveil.js';

document.documentElement.className = document.documentElement.className.replace('no-js', 'js')
if (isTouchDevice()) {
  document.documentElement.className += ' touch';
} else {
  document.documentElement.className += ' no-touch';
}

import './scripts/page.js';
import './scripts/solutions-slider.js';
import './scripts/tab.js';
import './scripts/slider.js';
import './scripts/slider-foot.js';
import './scripts/scroll-to.js';
import './scripts/page-feature.js';
import './scripts/nav.js';
import './scripts/nav-mobile.js';
import './scripts/map.js';
import './scripts/link.js';
import './scripts/link-block-text.js';
import './scripts/form-contact.js';
import './scripts/fixed-content.js';
import './scripts/box.js';
import './scripts/content-slideshow.js';
import './scripts/unveil.js';
import './scripts/subscribe-form.js';
import './scripts/overlay.js';
import './scripts/form-select-outer.js';
import './scripts/announcement.js';
import './scripts/paywall-form.js';
import './scripts/omnia-partners.js';

// i need this for HMR to work
if (module.hot) module.hot.accept();
