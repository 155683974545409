function checkContent() {
  if($('.js-content-slideshow').length){
    contentSlideshow();
  };

  if($('.video').length){
    $('body').fitVids();
  };
};

var $link = $('.js-link');
if ($link.length) {
  var baseTitle = document.title.split(' - '),
    dataToSave = {
    url: document.location.href,
    title: document.title
  };

  if (window.history && window.history.pushState) {
    history.replaceState(dataToSave, dataToSave.title, dataToSave.url);

    window.addEventListener('popstate', function(event) {
      if (event.state) {
        $link.removeClass('is-active');
        $('.js-link[href="'+event.state.url+'"]').addClass('is-active');

        document.title = event.state.title+' - '+baseTitle[1];

        $.get(event.state.url, function(data) {
          $('.js-data').html(data);
          checkContent();
        });
      };
    });
  };

  $(document).on('click', '.js-link', function(event) {
    event.preventDefault();
    var $this = $(this),
        url = $this.attr('href'),
        title = $this.text(),
        dataToSave = {
          url: url,
          title: title+' - '+baseTitle[1]
        };


    $link.removeClass('is-active');
    $('.nav-subnav li a').removeClass('is-active');
    $('.nav-subnav li a[href="'+url+'"]').addClass('is-active');
    $this.addClass('is-active');

    document.title = title+' - '+baseTitle[1];
    if (window.history && window.history.pushState) {
      history.pushState(dataToSave, title+' - '+baseTitle[1], url);
    }

    $.get(url + '?ajax=1', function(data) {
      $('.js-data').html(data);
      if ($this.hasClass('js-scroll-to-after')) {

        var dest = $this.attr('data-dest'),
            offset = 70;
        if ($(window).width() <= 1024) {
          offset = 82;
        } else if ($('.nav-subnav').hasClass('is-active')) {
          offset = 86;
        };
        $('html,body').animate({scrollTop: ($(dest).offset().top - offset)}, 900);
      }
      checkContent();
    });
  });

};
