var $navSwitch = $('.js-toggle-mobile-nav');
if ($navSwitch.length) {
  $navSwitch.on('click', function(e){
    e.preventDefault();
    $('.page').addClass('is-opened-mobile-nav');
    initMobileNav();
  });

  $('.nav-mobile-outer').on('click', function(e){
    if(!$(e.target).closest('.nav-mobile-links').length) {
      $('.page').removeClass('is-opened-mobile-nav');
      $('body').removeClass('is-mobile-nav-oversize');

      $('.js-nav-mobile-subnav.is-active').removeClass('is-active');
      $('.nav-mobile-sublinks.is-active').removeClass('is-active').slideUp();
    }
  });
};

var $navMobileSubnav = $('.js-nav-mobile-subnav');
if ($navMobileSubnav.length) {
  $navMobileSubnav.on('click', function(e){
    e.preventDefault();
    var $this = $(this);
    if ($this.hasClass('is-active')) {
      $this.removeClass('is-active').next().removeClass('is-active').slideUp('slow', function() {
        initMobileNav();
      });
    } else {
      $navMobileSubnav.removeClass('is-active');
      $('.nav-mobile-sublinks.is-active').removeClass('is-active').slideUp();
      $this.addClass('is-active').next().addClass('is-active').slideDown('slow', function() {
        initMobileNav();
      });
    }
  });
};

var initMobileNav = function() {
  var mobileNavHeight = $('.nav-mobile').height(),
      windowHeight = $(window).height();
  if (mobileNavHeight > windowHeight) {
    $('body').addClass('is-mobile-nav-oversize');
  } else {
    $('body').removeClass('is-mobile-nav-oversize');
  }
};
