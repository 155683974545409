var $sliderCenter = $('.js-slider-center');
if ($sliderCenter.length) {
  var itemsM = 1,
      itemsD = 2,
      margin = 0,
      autoplayOpt = false;

  if ($sliderCenter.hasClass('slider-about')) {
    itemsM = 3;
    itemsD = 7;
  }

  if ($sliderCenter.hasClass('slider-products')) {
    itemsM = 3;
    itemsD = 6;
  }

  if ($sliderCenter.hasClass('slider-agencies')) {
    itemsM = 1;
    itemsD = 3;
    autoplayOpt = true;
  }

  if ($sliderCenter.hasClass('slider-products')) {
    margin = 10;
  }

  $sliderCenter.owlCarousel({
    center: true,
    items: itemsM,
    loop: true,
    margin: margin,
    nav: true,
    dots: false,
    lazyLoad:true,
    navText:['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
    URLhashListener: true,
    startPosition: 'URLHash',
    autoplay: autoplayOpt,
    autoplayTimeout:2000,
    autoplayHoverPause:true,
    responsive:{
      768:{
        items: itemsD
      }
    },
    onInitialized: callback,
    onChange: callback
  });
};

function callback(event) {
  setTimeout(function() {
    var $current = $('.owl-item.active.center'),
        id = $current.find('.item').attr('data-id');

    $('.owl-item').removeClass('is-active-next').removeClass('is-active-prev');
    $current.prev().addClass('is-active-prev');
    $current.next().addClass('is-active-next');
    $('.slider-content').removeClass('is-active');
    $('#'+id).addClass('is-active');
  }, 250);
}
