import {getClosest} from './../utils/dom-utils';
import {createCookie} from './../utils/cookie';
// import {getCookie} from './../utils/cookie';

$(document).on('click', '.js-overlay-close', function(event) {
  event.preventDefault();
  var $parrent = $(this).closest('.js-overlay');
  if ($parrent.find('.ff-form-success')) {
    var days = 365;
  } else {
    var days = 7;
  }
  $parrent.remove();
  createCookie('subscribe-popup', 'hidden', days);
});

// document.addEventListener('click', function (event) {
  // if ( event.target.classList.contains('js-overlay-close') ) {
  //   getClosest(event.target, '.js-overlay').remove();
  //   createCookie('subscribe-popup', 'hidden', 7);
  // }
// }, false);

// window.addEventListener('load', function(){
//   var subscribeOverlay = document.getElementById('js-subscribe-overlay')
//   subscribeOverlay && subscribeOverlay.classList.add('-active')
// })

// if (!getCookie('subscribe-popup')) {
//   $(window).scroll(function(e) {
//     if ($(window).scrollTop() > 15) {
//       var subscribeOverlay = document.getElementById('js-subscribe-overlay');
//       subscribeOverlay && subscribeOverlay.classList.add('-active');
//     }
//   });
// }
