var $linkBlockText = $('.js-link-block-text');
if ($linkBlockText.length) {
  linkBlockTextSize();
  checkForChanges();

  $(window).on('resize', function() {
    linkBlockTextSize();
  });
};

function checkForChanges() {
  if ($('html').hasClass('wf-active')) {
    linkBlockTextSize();
  } else {
    setTimeout(checkForChanges, 500);
  }
};

function linkBlockTextSize() {
  var elHeight = 0;
  $linkBlockText.each(function(index, el) {
    if ($(el).height() > elHeight) {
      elHeight = $(el).height();
    }
  });
  $linkBlockText.parent().css('min-height', elHeight);
};
