function contentSlideshow() {
  setTimeout(function() {
    $('.js-content-slideshow').owlCarousel({
      loop:true,
      nav:true,
      dots:false,
      navText:['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
      items:1,
      autoHeight: true
    });
  }, 400);
};


if($('.js-content-slideshow').length){
  contentSlideshow();
};
