import isTouchDevice from '../utils/is-touch-device.js';

var $pageFeatureContent = $('.js-page-feature-content');
if ($pageFeatureContent.length) {
  var $win = $(window),
      divHeight = 659;

  if(!isTouchDevice()) {
    $win.scroll(function(e) {
      var fromTop = $win.scrollTop(),
          top = fromTop * .4,
          topSecond = fromTop * .2 * -1,
          opacity = 1 - (fromTop / (divHeight / 3));

      if (opacity < 0) {
        opacity = 0;
      }

      if (fromTop < divHeight) {
        $pageFeatureContent.css({
          'top': top,
          'opacity': opacity.toFixed(2)
        });
        // $('.ico-circle').css('margin-bottom', (topSecond * -1));
        $('.page-bg').css('top', topSecond);
      }
    });
  }
};
