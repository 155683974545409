var $navLink = $('.nav-li');
if ($navLink.length) {
  $navLink.mouseover( function(){
    var $navLiActive = $('.nav-li.is-active');

    $navLiActive
      .removeClass('is-active')
      .addClass('is-active-mark')
      .children('.nav-link')
        .removeClass('is-active')
        .addClass('is-active-mark');
  }).mouseout( function(){
    var $navLiActive = $('.nav-li.is-active-mark');

    $navLiActive
      .removeClass('is-active-mark')
      .addClass('is-active')
      .children('.nav-link')
        .removeClass('is-active-mark')
        .addClass('is-active');
  });
};
