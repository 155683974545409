// import 'owl.carousel/dist/assets/owl.carousel.css';

;(function($){

  $(function() {
    var $sliderSolutions = $('.js-solutions-slider').owlCarousel({
      loop:false,
      nav:true,
      lazyLoad: true,
      dots: false,
      navText:['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
      responsive:{
        0:{
          items:1
        },
        554:{
          items:2
        },
        768:{
          items:3
        },
        992:{
          items:4
        }
      }
    });
  });

  $(function() {
    var $sliderSolutions = $('.js-solutions-slider-loop').owlCarousel({
      loop:true,
      nav:true,
      lazyLoad: true,
      dots: false,
      navText:['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
      responsive:{
        0:{
          items:1
        },
        554:{
          items:2
        },
        768:{
          items:3
        },
        992:{
          items:4
        }
      }
    });
  });

  function callbackfun(event) {
    setTimeout(function() {
      var $current = $('.owl-item.active'),
          id = $current.find('.solution-slide').attr('data-id');

      $('.solution-content').removeClass('is-active');
      $('#'+id).addClass('is-active');
    }, 250);
  }

  $(function() {
    var $sliderSolutions = $('.js-solutions-slider-center').owlCarousel({
      //center: true,
      loop:true,
      nav:true,
      lazyLoad: true,
      dots: false,
      navText:['<i class="icon-left-open-big"></i>', '<i class="icon-right-open-big"></i>'],
      responsive:{
        0:{
          items:1
        },
        554:{
          items:1
        },
        768:{
          items:1
        },
        992:{
          items:1
        }
      },
      onInitialized: callbackfun,
      onChange: callbackfun
    });
  });
})(jQuery);
