import {createCookie} from './../utils/cookie';
import {getCookie} from './../utils/cookie';

$(function () {
  // show the form
  if (!getCookie('paywall-popup')) {
    const paywall = document.getElementById("js-paywall");
    if (!paywall.classList.contains('is-hidden-onstart')) {
      paywall.classList.remove('is-hidden');
    }
  }

  const formPaywall = $('#paywall-form');
  if ( formPaywall.length ) {
    const myForm = document.getElementById("paywall-form");
    const $download = $("#download-file");
    const paywall = document.getElementById("js-paywall");

    // on success
    myForm.addEventListener("freeform-ajax-success", function (event) {
      const form = event.form;
      const response = event.response;

      createCookie('paywall-popup', 'hidden', 365);
      setTimeout(function () {
        paywall.classList.add('is-hidden');
      }, 1000);
      if ($download.length) {
        location.href = '/download.php?file=' + $download.data('file');
      }
    });

    // blitz cache fix
    $.ajax({
      // Specify the form handle in the GET parameters
      url: '/dynamic/?form=paywall',
      type: 'get',
      dataType: 'json',
      success: function (response) {
        // Update the Form Hash
        formPaywall.find('input[name=formHash]').val(response.hash);

        // Locate and update the CSRF input
        var csrf = response.csrf;
        formPaywall.find('input[name=' + csrf.name + ']').val(csrf.value);

        // Update the JS Honeypot (ONLY when JS Enhancement setting is ON)
        var honeypot = response.honeypot;
        var honeypotInput = formPaywall.find('input[name^="freeform_form_handle"]');
        honeypotInput.attr('id', honeypot.name);
        honeypotInput.attr('name', honeypot.name);
        honeypotInput.val(honeypot.hash);
      },
    });
  }

  $('.js-paywall-open').on('click', function(e){
    $('#js-paywall').removeClass('is-hidden');
    $('html,body').animate({scrollTop: 0 }, 900);
  });
});
