import {createCookie} from './../utils/cookie';
import {getCookie} from './../utils/cookie';

window.renderFormSuccess = function (form) {
    // const successMessage = document.createElement("div");
    // successMessage.classList.add("alert", "alert-success", "form-success");

    // const paragraph = document.createElement("p");
    // paragraph.classList.add("lead");
    // paragraph.appendChild(document.createTextNode("Form has been submitted successfully!"));

    const successMessage = '<div class="text-center"><h2 class="overlay-title">Thanks for signing up!</h2><p class="overlay-subline">You can unsubscribe at any time using the Unsubscribe link at the bottom of every email.</p></div>'

    form.insertAdjacentHTML('beforebegin', successMessage);
    form.remove();
    createCookie('subscribe-popup', 'hidden', 365);
};

window.renderFormErrors = (errors, form) => {
  const errorBlock = document.createElement("div");
  errorBlock.classList.add("alert", "alert-danger", "errors");

  const paragraph = document.createElement("p");
  paragraph.appendChild(document.createTextNode("Error! Please review the form and try submitting again."));
  paragraph.classList.add("lead");
  errorBlock.appendChild(paragraph);

  if (errors.length) {
    const errorsList = document.createElement("ul");
    for (let messageIndex = 0; messageIndex < errors.length; messageIndex++) {
      const message = errors[messageIndex];
      const listItem = document.createElement("li");

      listItem.appendChild(document.createTextNode(message));
      errorsList.appendChild(listItem);
    }

    errorBlock.appendChild(errorsList);
  }

  form.insertBefore(errorBlock, form.childNodes[0]);
};

$(function () {
  if (!getCookie('subscribe-popup')) {
    var $subscribeOverlay = $('#js-subscribe-overlay');
    var form = $subscribeOverlay.find('form');
    if ( form.length ) {
      $.ajax({
        // Specify the form handle in the GET parameters
        url: '/dynamic/?form=emailSubscription',
        type: 'get',
        dataType: 'json',
        success: function (response) {
          // snaptcha
          form.find('#note').val(response.snaptcha);

          // Update the Form Hash
          form.find('input[name=formHash]').val(response.hash);

          // Locate and update the CSRF input
          var csrf = response.csrf;
          form.find('input[name=' + csrf.name + ']').val(csrf.value);

          // Update the JS Honeypot (ONLY when JS Enhancement setting is ON)
          var honeypot = response.honeypot;
          var honeypotInput = form.find('input[name^="freeform_form_handle"]');
          honeypotInput.attr('id', honeypot.name);
          honeypotInput.attr('name', honeypot.name);
          honeypotInput.val(honeypot.hash);

          $subscribeOverlay.addClass('-active');
        },
      });
    }
  }

  var form2 = $('#form-custom form');
  if ( form2.length ) {
    $.ajax({
      // Specify the form handle in the GET parameters
      url: '/dynamic/?form=bookYourLunch',
      type: 'get',
      dataType: 'json',
      success: function (response) {
        // snaptcha
        form2.find('#note2').val(response.snaptcha);

        // Update the Form Hash
        form2.find('input[name=formHash]').val(response.hash);

        // Locate and update the CSRF input
        var csrf = response.csrf;
        form2.find('input[name=' + csrf.name + ']').val(csrf.value);

        // Update the JS Honeypot (ONLY when JS Enhancement setting is ON)
        var honeypot = response.honeypot;
        var honeypotInput = form2.find('input[name^="freeform_form_handle"]');
        honeypotInput.attr('id', honeypot.name);
        honeypotInput.attr('name', honeypot.name);
        honeypotInput.val(honeypot.hash);
      },
    });
  }
});
