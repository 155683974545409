
setTimeout(function() {
  $('.js-slider-foot').owlCarousel({
    items: 1,
    animateOut: 'fadeOut',
    nav: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true
  });
}, 250)
