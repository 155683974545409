import {createCookie} from './../utils/cookie';

var $page = $('.js-page'),
    $win = $(window),
    $helloBar = $('.hello-bar'),
    $header = $('.l-header');

if ($page.length) {
  if ($helloBar.length) {
    var helloBarHeight = $helloBar.innerHeight();
    $header.css('top', helloBarHeight);

    $win.on('resize', function() {
      helloBarHeight = $helloBar.innerHeight();
      $header.css('top', helloBarHeight);
    });

    $(window).scroll(function(e) {
      helloBarHeight = $helloBar.innerHeight();

      if ($win.scrollTop() >= helloBarHeight) {
        $header.css('top', 0).addClass('is-header-fixed');
      } else {
        $header.css('top', helloBarHeight).removeClass('is-header-fixed');
      };

      if ($win.scrollTop() >= (25 + helloBarHeight)) {
        $page.addClass('is-scrolling');
      } else {
        $page.removeClass('is-scrolling');
      };
    });

    $('.js-hello-bar-cancel').on('click', function(e){
      e.preventDefault();
      createCookie('hello-bar', 'hidden', 5);
      $helloBar.remove();
      helloBarHeight = 0;
      $header.css('top', 0);
    });

    $('.js-hello-bar-accept').on('click', function(e){
      createCookie('hello-bar', 'hidden', 5);
      $helloBar.remove();
      helloBarHeight = 0;
      $header.css('top', 0);
    });

  } else {

    $header.css('top', 0).addClass('is-header-fixed');
    $(window).scroll(function(e) {
      if ($win.scrollTop() >= 25) {
        $page.addClass('is-scrolling');
      } else {
        $page.removeClass('is-scrolling');
      };
    });

  };

  if ($page.hasClass('is-index-page')) {
    $(window).on('load', function() {
      $page.addClass('is-active-page');
    });
  };

};
