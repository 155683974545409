var $fixedContent = $('.js-fixed-content');
if ($fixedContent.length) {
  var $win = $(window),
    winHeight = $win.height();

  // $fixedContent.css('height', winHeight);

  $win.on('resize', function() {
    winHeight = $win.height();
    // $fixedContent.css('height', winHeight);
  });

  $win.scroll(function(e) {
    var fromTop = $win.scrollTop(),
        top = fromTop * .4,
        opacity = 1 - (fromTop / (winHeight / 3));

    if (opacity < 0) {
      opacity = 0;
    }

    if (fromTop < winHeight) {
      $('.js-fixed-content-text').css({
        'top': top,
        'opacity': opacity.toFixed(2)
      });
    }
  });
};
