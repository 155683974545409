var $formContact = $('.js-form-contact');
if ( $formContact.length ) {
  $formContact.find('.btn').on( 'click', function() {
    var $email = $('#fromEmail'),
        emailValue = $email.val(),
        $emailMsg = $('#messageEmail');

    $emailMsg.val(emailValue);
  });
};
