var $tabLink = $('.js-tab-link'),
    $tabBody = $('.js-tab-body');
if ($tabLink.length) {
  $tabLink.parent().first().children().addClass('is-active');
  $tabBody.first().addClass('is-active');

  $tabLink.on('click', function(event) {
    event.preventDefault();
    $tabLink.removeClass('is-active');
    var id = $(this).addClass('is-active').attr('href');

    $tabBody.removeClass('is-active');
    $(id).addClass('is-active');
  });
};
