var $scrollTo = $('.js-scroll-to');
if ($scrollTo.length) {
  $scrollTo.on('click', function(e) {
    e.preventDefault();
    var dest = $(this).attr('data-dest'),
        offset = 70;

    if ($(window).width() <= 1024) {
      offset = 82;
    } else if ($('.nav-subnav').hasClass('is-active')) {
      offset = 86;
    };
    $('html,body').animate({scrollTop: ($(dest).offset().top - offset)}, 900);
  });
};
