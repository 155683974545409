$(function() {

  $('.b-lazy').unveil(100, function() {
    $(this).on('load', function() {
      var parent = this.parentNode;
      parent.className = parent.className.replace(/\bloading\b/,'');
    });
  });

});

$(window).on('load', function() {
  $(window).trigger('lookup');
});